<!--
 * @Description: 底部导航设计页配置页
-->
<template>
	<div class="wrap">
		<!-- 导航展示 -->
		<div style="padding-right: 360px">
			<phone-ctn>
				<div class="edit">
					<nav-bar v-bind="project.config.navigation" :disabled="true"></nav-bar>
				</div>
			</phone-ctn>
		</div>

		<!-- 导航配置 -->
		<config-ctn title="底部导航">
			<config-wrap>
				<div class="mb15 f13">导航</div>
				<div class="nav">
					<div class="mt10 mb15 f12 f-grey">可配置 1 至 4 个导航</div>
					<div class="mt10 mb15 f12 f-red">首页导航不可配置跳转</div>
					
					
					<config-item label='首页选中' v-if="navigation.styles.tabsCustomIcon==1">
						<FaImageUpload :maxCount="1" v-model="navigation.styles.tabsHomeSelectedIcon" />
					</config-item>
					<config-item label='首页待选'  v-if="navigation.styles.tabsCustomIcon==1">
						<FaImageUpload :maxCount="1" v-model="navigation.styles.tabsHomeUnselectIcon"  />
					</config-item>

					<div class="mt10 mb15 f12 f-red">
						<config-item label='自定义图标'  >
							<el-switch class="mt8" v-model="navigation.styles.tabsCustomIcon">
							</el-switch>
						</config-item>
						<config-item label="选中颜色">
							<config-color-picker v-model="navigation.styles.tabsActiveColor"></config-color-picker>
							<!-- 					<el-input v-model="navigation.styles.tabsActiveColor" size="small" maxlength="7" show-word-limit>								
							</el-input> -->
						</config-item>
					</div>



					<draggable v-model="navigation.list">
						<div class="nav-item" v-for="(item, index) in navigation.list" v-if="index > 0" :key="item.id">
							<div v-if="navigation.list.length > 2" class="nav-delete"
								@click="navigation.list.splice(index, 1)">
								<i class="f12 icon icon-cha-"></i>
							</div>
							
							<config-item label="菜单图标" class="flex f13" v-if="navigation.styles.tabsCustomIcon!=1">
								<!-- 选择图标 -->
								<icon-select v-model="item.icon"></icon-select>
							</config-item>
							
							<config-item label='选中图标' v-if="navigation.styles.tabsCustomIcon==1">
								<FaImageUpload :maxCount="1" v-model="item.selectedIcon"  />
							</config-item>
							<config-item label='待选图标'  v-if="navigation.styles.tabsCustomIcon==1">
								<FaImageUpload :maxCount="1" v-model="item.unselectIcon"  />
							</config-item>
							<config-item label="菜单名称">
								<el-input v-model="item.text" size="small" maxlength="4" show-word-limit></el-input>
							</config-item>
							<config-item label="跳转页面">
								<jump-select v-model="item.jump"></jump-select>
							</config-item>
						</div>
					</draggable>

					<div v-if="navigation.list.length < 4" class="nav-add" @click="add">
						新增导航
					</div>

				</div>
			</config-wrap>
		</config-ctn>
	</div>
</template>

<script>
	import NavBar from "@/components/NavBar";
	import IconSelect from "@/components/IconSelect";
	import {
		mapGetters
	} from "vuex";

	export default {
		components: {
			NavBar,
			IconSelect,
		},

		computed: {
			...mapGetters(["project"]),

			navigation() {
				return this.project.config.navigation;
			},
		},
		mounted() {
			console.log('导航参数----->', this.project);

			// this.project.setting = {
			// 		login: {
			// 			backgroundColor: '',
			// 			bgImg: 'https://h5.5izg.bj.cn/img/1713584176572.png',
			// 			btnTitle: '会员登录',
			// 			btnBgColor: 'red',
			// 			btnTxtColor: '',
			// 			logo: 'https://mall.5izg.cn/upload/202202/22/202202221249385755.png',  //默认logo
			// 			btnTitle: '登录',
			// 			btnHeight: '48px',
			// 			btnTitleSize: '14',
			// 			btnUpperRadius: 10, //按钮顶部2个角的圆弧度
			// 			btnLowerRadius: 10,
			// 			btnMarginTop: 10,
			// 			btnMarginBottom: 10
			// 		}

			// 	}; //页面背景图 标题 分享图片和文字等配置	


			if (!this.project.hasOwnProperty('setting')) {
				//this.project.setting = {}; //页面背景图 标题 分享图片和文字等配置	
				this.project.setting = {
					login: {
						backgroundColor: '',
						bgImg: '',
						btnTitle: '会员登录',
						btnBgColor: 'red',
						btnTxtColor: '',
						logo: 'https://mall.5izg.cn/upload/202202/22/202202221249385755.png', //默认logo
						inputMarginTop: '100px',
						inputMarginBottom: '0',
						btnTitle: '登录',
						btnHeight: '48px',
						btnWidth: '90vw',
						btnTitleSize: '14',
						btnUpperRadius: 10, //按钮顶部2个角的圆弧度
						btnLowerRadius: 10,
						btnMarginTop: 10,
						btnMarginBottom: 10
					},
					me: {
						headImg: "",
					},
					agreement: {
						title: '',
						btnTitle: '确认',
						btnBgColor: 'red',
					},
					modeSelect: {
						btnTitle: '',
						btnBgColor: 'red',
					}

				}; //页面背景图 标题 分享图片和文字等配置	

			}
			// if (!this.project.setting.length == 0) {
			// 	//this.project.setting = {}; //页面背景图 标题 分享图片和文字等配置	
			// 	this.project.setting = {
			// 		login: {
			// 			backgroundColor: '',
			// 			bgImg: 'https://h5.5izg.bj.cn/img/1713584176572.png',
			// 			btnTitle: '会员登录',
			// 			btnBgColor: 'red',
			// 			btnTxtColor: '',
			// 			logo: 'https://mall.5izg.cn/upload/202202/22/202202221249385755.png',  //默认logo
			// 			btnTitle: '登录',
			// 			btnHeight: '48px',
			// 			btnTitleSize: '14',
			// 			btnUpperRadius: 10, //按钮顶部2个角的圆弧度
			// 			btnLowerRadius: 10,
			// 			btnMarginTop: 10,
			// 			btnMarginBottom: 10
			// 		}

			// 	}; //页面背景图 标题 分享图片和文字等配置	


			// }
			// if (!this.project.setting.hasOwnProperty('login')) {
			// 	this.project.setting = {
			// 		login: {
			// 			backgroundColor: '',
			// 			bgImg: 'https://h5.5izg.bj.cn/img/1713584176572.png',
			// 			btnTitle: '会员登录',
			// 			btnBgColor: 'red',
			// 			btnTxtColor: '',
			// 			logo: 'https://mall.5izg.cn/upload/202202/22/202202221249385755.png',  //默认logo
			// 			btnTitle: '登录',
			// 			btnHeight: '48px',
			// 			btnTitleSize: '14',
			// 			btnUpperRadius: 10, //按钮顶部2个角的圆弧度
			// 			btnLowerRadius: 10,
			// 			btnMarginTop: 10,
			// 			btnMarginBottom: 10
			// 		}

			// 	}; //页面背景图 标题 分享图片和文字等配置	
			// }
		},
		onLoad() {
			console.log('导航参数----->', this.navigation.list);

		},

		methods: {
			add() {
				//console.log('导航参数----->', this.project.config.navigation);
				this.navigation.list.push({
					value: ""
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.edit {
		display: flex;
		align-items: flex-end;
		width: 100%;
		min-height: 603px
			/*no*/
		;
		background: #f7f8fa;
	}
</style>